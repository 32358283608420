import React, { type FC, useState, useEffect, useContext } from 'react';
import { useInView } from 'react-intersection-observer';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box } from '@atlaskit/primitives';
import { usePageLayoutResize } from '@atlaskit/page-layout';

import { SPAViewContext } from '@confluence/spa-view-context';
import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';

import {
	useJiraProjectBoardExperiment,
	JIRA_PROJECT_BOARD_EXP_NAME,
} from './hooks/useJiraProjectBoardExperiment';
import { JiraBoardNavigationItem } from './ui/JiraBoardNavigationItem';

type Props = {
	spaceKey: string;
	isNav4Enabled: boolean;
};

export const JiraBoardNavigationItemWrapperBase: FC<Props> = ({ spaceKey, isNav4Enabled }) => {
	const [isNavItemVisible, setIsNavItemVisible] = useState(false);

	const { isSiteAdmin } = useContext(SPAViewContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isLeftSidebarCollapsed } = usePageLayoutResize();
	const [linkRef, inView] = useInView({ triggerOnce: true });

	const { isJiraProjectBoardExperimentEnabled, experimentCohort, fireJiraProjectBoardExperiment } =
		useJiraProjectBoardExperiment(isNav4Enabled);

	useEffect(() => {
		if (['control', 'variation'].includes(experimentCohort) && inView && !isLeftSidebarCollapsed) {
			setIsNavItemVisible(true);
			fireJiraProjectBoardExperiment();
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'exposed',
					actionSubject: 'feature',
					source: 'jiraProjectBoardMenuItem',
					attributes: {
						flagKey: JIRA_PROJECT_BOARD_EXP_NAME,
						cohort: experimentCohort,
						growthInitiative: 'cross-flow',
						isSiteAdmin,
					},
				},
			}).fire();
		}
	}, [
		inView,
		isLeftSidebarCollapsed,
		createAnalyticsEvent,
		experimentCohort,
		isSiteAdmin,
		fireJiraProjectBoardExperiment,
	]);

	return (
		<Box ref={linkRef} data-vc="jira-board-navigation-item">
			{isJiraProjectBoardExperimentEnabled && (
				<JiraBoardNavigationItem
					spaceKey={spaceKey}
					isNav4Enabled={isNav4Enabled}
					isNavItemVisible={isNavItemVisible}
				/>
			)}
		</Box>
	);
};

export const JiraBoardNavigationItemWrapper: FC<Props> = (props) => {
	return (
		<TransparentErrorBoundary attribution={Attribution.GROWTH_TRAILBLAZERS}>
			<JiraBoardNavigationItemWrapperBase {...props} />
		</TransparentErrorBoundary>
	);
};
