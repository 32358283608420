import { useIntl } from 'react-intl-next';

import { useSessionData, AccessStatus } from '@confluence/session-data';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

export const JIRA_PROJECT_BOARD_EXP_NAME = 'jira_board_in_confluence_v2';
export const COHORT = {
	VARIATION: 'variation',
	CONTROL: 'control',
	NOT_ENROLLED: 'not-enrolled',
} as const;

export const useJiraProjectBoardExperiment = (isNav4Enabled: boolean) => {
	const { locale } = useIntl();
	const { accessStatus, userId } = useSessionData();

	let hasAccess =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;

	// SSR team is testing userId check - we should not let users into experiment if they don't qualify
	// https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/pull-requests/188134/overview
	hasAccess = hasAccess && !!userId;
	const isEnglishLocale = (locale || '').toLowerCase().startsWith('en');

	if (
		!isEnglishLocale ||
		!hasAccess ||
		isNav4Enabled // TBLZ-2088: disable navigation item when isNav4Enabled
	) {
		return {
			isJiraProjectBoardExperimentEnabled: false,
			experimentCohort: COHORT.NOT_ENROLLED,
			fireJiraProjectBoardExperiment: () => {},
		};
	}

	const [experiment, fireJiraProjectBoardExperiment] = UNSAFE_noExposureExp(
		'jira_board_in_confluence_v2',
	);
	const experimentCohort = experiment.get<string>('cohort', COHORT.NOT_ENROLLED);

	const isJiraProjectBoardExperimentEnabled = experimentCohort === COHORT.VARIATION;

	return { isJiraProjectBoardExperimentEnabled, experimentCohort, fireJiraProjectBoardExperiment };
};
