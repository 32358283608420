import React, { type ReactNode, type FC, useState, useCallback, useEffect, useRef } from 'react';
import { Coordination } from '@atlassiansox/engagekit-ts';

import { token } from '@atlaskit/tokens';
import { SpotlightTarget, Spotlight, SpotlightTransition } from '@atlaskit/onboarding';
import { LinkItem } from '@atlaskit/side-navigation';
import BoardIcon from '@atlaskit/icon/core/board';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';
import { AdControlProvider } from '@atlassian/ad-control-toolkit';

import { useRouteName } from '@confluence/route-manager';
import { Attribution, TransparentErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { SPACE_JIRA_PROJECT_BOARD } from '@confluence/named-routes';
import { useCoordinationClient } from '@confluence/engagement-provider';
import {
	confluenceLocalStorageInstance,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

import { useUserState } from '../hooks/useUserState';

import { AdMenu } from './AdMenu';

export const SPOTLIGHT_MESSAGE_ID = 'cc-jira-board-spotlight-v2-message-id';
export const SPOTLIGHT_TARGET_ID = 'jira-board-in-confluence-spotlight-id';

const DISMISSALS_STORAGE_KEY = PERSISTED_KEYS_ON_SERVER.PERSISTED_JIRA_AD_DISMISSAL;

type JiraBoardNavigationItemProps = {
	spaceKey: string;
	isNavItemVisible: boolean;
	isNav4Enabled: boolean;
};

// Copied from confluence/next/packages/side-navigation/src/SpaceNavigation/SpaceLinks.tsx, needed for consistency with the other menu items
const cssFn = ({ isSelected = false, isDatabaseIcon = false }) => ({
	padding: `0px ${token('space.050')} 0px ${token('space.100')}`,
	height: '36px',
	'min-height': '36px',
	'border-radius': '3px',
	font: token('font.body'),
	span: {
		columnGap: token('space.100'),
	},
	backgroundColor: isSelected ? token('color.background.selected') : 'transparent',
	'&:hover': {
		backgroundColor: isSelected
			? token('color.background.selected.hovered')
			: token('color.background.neutral.subtle.hovered'),
	},
	'&, &:visited, &:active, &:focus': {
		color: isSelected ? token('color.text.selected') : token('color.text.subtle'),
	},
	textDecoration: 'none !important',
	'[data-item-elem-before=true]': {
		'margin-right': '0px',
		height: '24px',
		width: '24px',
		'img, span, svg': {
			height: isDatabaseIcon ? '18px' : '20px',
			width: isDatabaseIcon ? '18px' : '20px',
		},
	},
});

interface MenuItemComponentProps {
	isNav4Enabled: boolean;
	href: string;
	iconBefore: ReactNode;
	iconAfter: ReactNode;
	children: ReactNode;
	isSelected: boolean;
}

const MenuItemComponent = ({
	isNav4Enabled,
	href,
	iconBefore,
	iconAfter,
	isSelected,
	children,
}: MenuItemComponentProps) => {
	if (isNav4Enabled) {
		return (
			<MenuLinkItem
				href={href}
				elemBefore={iconBefore}
				elemAfter={iconAfter}
				isSelected={isSelected}
			>
				{children}
			</MenuLinkItem>
		);
	}

	return (
		<LinkItem
			// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
			cssFn={cssFn}
			href={href}
			iconBefore={iconBefore}
			iconAfter={iconAfter}
			isSelected={isSelected}
		>
			{children}
		</LinkItem>
	);
};

export const JiraBoardNavigationItemBase: FC<JiraBoardNavigationItemProps> = ({
	spaceKey,
	isNavItemVisible,
	isNav4Enabled,
}) => {
	const [isSpotlightActive, setIsSpotlightActive] = useState(true);
	const coordinationClient = useCoordinationClient();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { hasJira, isSiteAdmin, isLoading, userStateDataError } = useUserState();
	const hasSpotlightScreenViewedEventBeenFired = useRef(false);

	const isOnJiraBoardRouteArgs = {
		selector: (routeName: string | undefined) => routeName === SPACE_JIRA_PROJECT_BOARD.name,
	};
	const isOnJiraBoardRoute = useRouteName(isOnJiraBoardRouteArgs);

	const [isAdDismissed, setIsAdDismissed] = useState<boolean>(
		confluenceLocalStorageInstance.getItemAsBoolean(DISMISSALS_STORAGE_KEY),
	);

	// The ad control dismissal is relevant only until they provision Jira
	const showAdMenu = !hasJira && !isLoading && !userStateDataError;

	const FireSpotlightScreenViewedEvent: FC = () => {
		const { createAnalyticsEvent } = useAnalyticsEvents();

		useEffect(() => {
			if (!hasSpotlightScreenViewedEventBeenFired.current) {
				createAnalyticsEvent({
					type: 'sendScreenEvent',
					data: {
						name: 'jiraBoardSpotlight',
						source: 'JiraBoardNavigationItemNudge',
					},
				}).fire();
				hasSpotlightScreenViewedEventBeenFired.current = true;
			}
		}, [createAnalyticsEvent]);

		return null;
	};

	const onDismiss = useCallback(async () => {
		setIsSpotlightActive(false);
		await coordinationClient.stop(SPOTLIGHT_MESSAGE_ID);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'jiraBoardSpotlightDismissed',
				source: 'JiraBoardNavigationItemNudge',
			},
		}).fire();
	}, [coordinationClient, createAnalyticsEvent]);

	const onTryNow = useCallback(async () => {
		setIsSpotlightActive(false);
		await coordinationClient.stop(SPOTLIGHT_MESSAGE_ID);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'jiraBoardSpotlightTryNow',
				source: 'JiraBoardNavigationItemNudge',
			},
		}).fire();
	}, [coordinationClient, createAnalyticsEvent]);

	const dismissAd = useCallback(async () => {
		confluenceLocalStorageInstance.setItem(DISMISSALS_STORAGE_KEY, true);
		setIsAdDismissed(true);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'notInterested',
				source: 'JiraBoardNavigationAdControl',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	// The ad control dismissal is relevant only until they provision Jira
	if (!hasJira && isAdDismissed) {
		return null;
	}

	return (
		<>
			{userStateDataError && <ErrorDisplay error={userStateDataError} />}
			<SpotlightTarget name={SPOTLIGHT_TARGET_ID}>
				<MenuItemComponent
					isNav4Enabled={isNav4Enabled}
					href={SPACE_JIRA_PROJECT_BOARD.toUrl({ spaceKey })}
					iconBefore={<BoardIcon spacing="spacious" label="" />}
					iconAfter={showAdMenu && <AdMenu onDismiss={dismissAd} isSiteAdmin={isSiteAdmin} />}
					isSelected={isOnJiraBoardRoute}
				>
					Jira project
				</MenuItemComponent>
			</SpotlightTarget>
			<SpotlightTransition>
				{isSpotlightActive && isNavItemVisible && (
					<Coordination client={coordinationClient} messageId={SPOTLIGHT_MESSAGE_ID}>
						<>
							<FireSpotlightScreenViewedEvent />
							<Spotlight
								actions={[
									{
										href: SPACE_JIRA_PROJECT_BOARD.toUrl({ spaceKey }),
										onClick: async () => {
											await onTryNow();
										},
										text: 'Try now',
									},
									{
										onClick: onDismiss,
										text: 'Dismiss',
									},
								]}
								heading="Manage projects within Confluence"
								target={SPOTLIGHT_TARGET_ID}
								key={SPOTLIGHT_TARGET_ID}
								targetRadius={3}
								dialogPlacement="right bottom"
								dialogWidth={275}
							>
								Link real-time data to any document, get automatic updates, and easily create
								reports.
							</Spotlight>
						</>
					</Coordination>
				)}
			</SpotlightTransition>
		</>
	);
};

export const JiraBoardNavigationItem: FC<JiraBoardNavigationItemProps> = (props) => {
	return (
		<TransparentErrorBoundary attribution={Attribution.GROWTH_TRAILBLAZERS}>
			<AdControlProvider>
				<JiraBoardNavigationItemBase {...props} />
			</AdControlProvider>
		</TransparentErrorBoundary>
	);
};
